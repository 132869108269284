<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-450px scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <div class="col-12 h-75px">
            <v-select
              v-model="formData.customer_type"
              label="Customer type"
              clearable
              item-text="text"
              item-value="index"
              outlined
              dense
              :items="serverData.customer_type"
              :error-messages="customerTypeErrors"
              @input="$v.formData.customer_type.$touch()"
              @blur="$v.formData.customer_type.$touch()"
            ></v-select>
          </div>
          <div class="col-12 h-75px">
            <v-select
              v-model="formData.status_id"
              label="Status"
              clearable
              item-text="text"
              item-value="index"
              :items="serverData.statuses"
              outlined
              dense
              :error-messages="statusErrors"
              @input="$v.formData.status_id.$touch()"
              @blur="$v.formData.status_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.template_id"
              label="Template"
              clearable
              outlined
              dense
              :error-messages="templateErrors"
              @input="$v.formData.template_id.$touch()"
              @blur="$v.formData.template_id.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.channel_id"
              label="Channel"
              clearable
              outlined
              dense
              :error-messages="channelErrors"
              @input="$v.formData.channel_id.$touch()"
              @blur="$v.formData.channel_id.$touch()"
            ></v-text-field>
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            @click.prevent="submit"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "MyAliceActionDialog",
  props: ["refresher", "pageLoader", "action_type", "item"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      customer_type: { required },
      status_id: { required },
      template_id: { required },
      channel_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    formData: {},
    serverData: null,
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Value should be greater than 0");
      }
      if ("maxValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].maxValue &&
          errors.push("Value should be less than 5");
      }
      if ("url" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].url && errors.push("This url is invalid");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      }
      this.loadDataFromServer().then(() => {
        this.dialog = !this.dialog;
      });
    },
    resetFormData() {
      this.formData = {
        key: null,
        value: null,
      };
      this.$v.$reset();
    },
    loadDataFromServer() {
      this.pageLoader(true);
      const url = {
        create: "create",
        edit: "edit",
      };

      const payload = {
        webhook_id: this.itemForAction.id,
        id: this.item?.id,
      };

      return ApiService.post(
        `/datahub/my_alice_config/${url[this.action_type]}`,
        payload
      )
        .then(({ data }) => {
          this.serverData = data;
          if (data.my_alice_config) {
            this.formData = { ...data.my_alice_config };
          }
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.pageLoader(true);

      const url =
        this.action_type === "edit"
          ? `/datahub/my_alice_config/update`
          : `/datahub/my_alice_config/store`;
      const payload = {
        webhook_id: this.itemForAction.id,
        ...this.formData,
      };

      ApiService.post(url, payload)
        .then(({ data }) => {
          if (!data.error) {
            if (this.action_type === "create") {
              swalAdded();
            } else {
              swalEdited();
            }
            this.refresher();
            this.toggleModal();
          } else {
            Swal.fire({
              title: "Error",
              text: `${data.message}`,
              icon: "error",
              showConfirmButton: true,
              // timer: 2500,
            });
          }
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    //if true, we are editing item, false we are adding
    itemForAction: function () {
      return this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.action_type === "edit") return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.action_type === "edit") return "Edit Item";
      else return "Add New Item";
    },
    customerTypeErrors: function () {
      return this.handleFormValidation("customer_type");
    },
    statusErrors: function () {
      return this.handleFormValidation("status_id");
    },
    templateErrors: function () {
      return this.handleFormValidation("template_id");
    },
    channelErrors: function () {
      return this.handleFormValidation("channel_id");
    },
  },
};
</script>

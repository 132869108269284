<template>
  <div class="d-flex">
    <social :data="facebook" :url="data.facebook"></social>
    <social :data="linkedin" :url="data.linkedin"></social>
    <social :data="twitter" :url="data.twitter"></social>
    <social :data="instagram" :url="data.instagram"></social>
    <social :data="youtube" :url="data.youtube"></social>
    <social :data="pinterest" :url="data.pinterest"></social>
  </div>
</template>

<script>
import Social from "./Social";
export default {
  name: "soical",
  props: ["data"],
  components: {
    Social,
  },

  mounted() {
    // console.log("mounted");
  },
  data: () => ({
    twitter: {
      name: "twitter",
      icon: "mdi-twitter",
      color: "blue lighten-1",
    },
    facebook: {
      icon: "mdi-facebook-box",
      color: "blue darken-2",
    },
    linkedin: {
      icon: "mdi-linkedin-box",
      color: "blue darken-2",
    },
    instagram: {
      icon: "mdi-instagram",
      color: "blue darken-2",
    },
    youtube: {
      icon: "mdi-youtube",
      color: "blue darken-2",
    },
    pinterest: {
      icon: "mdi-pinterest",
      color: "blue darken-2",
    },
  }),
};
</script>
